import { SearchIcon } from "@chakra-ui/icons";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSearchQuery } from "../lib/GraphAPI";
import { useAppDispatch } from "../lib/hooks";

function Query(props: { query: string, setQuery: Function }) {
    const dispatch = useAppDispatch()
    const { data, error, isLoading, isSuccess } = useSearchQuery(props.query);
    if (data && isSuccess) {
        dispatch({ type: "view/search", payload: data })
        props.setQuery(undefined)
    }

    return null
}

export default function SearchBar(props: { style?: React.CSSProperties}) {
    const [searchTerm, setSearchTerm] = useState<string>()
    const [query, setQuery] = useState("")
    const handleChange = (event: any) => setSearchTerm(event.target.value)

    return (
        <InputGroup style={props.style}>
            <InputLeftElement pointerEvents='none'>
                <SearchIcon color='gray.300' />
            </InputLeftElement>
            <Input type='search' placeholder='Search...' value={searchTerm}
                onChange={handleChange} onKeyDown={(event) => { if (event.code === 'Enter') setQuery(searchTerm!) }} />
            {query ? <Query query={query} setQuery={setQuery} /> : null}
        </InputGroup>
    )
}