import { Button, Stack, useToast } from "@chakra-ui/react";
import { useAppDispatch } from "../../../lib/hooks";
import { GraphView } from "../../../lib/ViewTypes";
import { getDefaultGraphView } from "../../../lib/GraphFactory";

export default function NodeContextMenu(props: {
  nodeId: string;
  id: string;
  graphViewIndex: number;
  position: { x: number; y: number };
  loadNeighbours: (id: string) => void;
}) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  return (
    <div
      style={{
        position: "absolute",
        top: props.position.y,
        left: props.position.x,
      }}
    >
      <Stack>
        <Button
          onClick={() => {
            let newGraphView = getDefaultGraphView(props.id);
            dispatch({
              type: "view/removeNodeContextMenu",
              payload: { graphViewIndex: props.graphViewIndex },
            });
            dispatch({ type: "view/addNewGraphView", payload: newGraphView });
            toast({
              title: "Graph opened",
              description: "Your Graph has been opened in a new tab",
              status: "info",
              duration: 3000,
              isClosable: true,
            });
          }}
        >
          New Center
        </Button>
        <Button
          onClick={() => {
            props.loadNeighbours(props.id);
            toast({
              title: "Neigbours loaded",
              description: "The new neighbours might not be visible due to filters",
              status: "info",
              duration: 3000,
              isClosable: true,
            });
          }}
        >
          Expand Neighbours
        </Button>
      </Stack>
    </div>
  );
}
