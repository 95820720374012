import { UnknownAction, configureStore } from "@reduxjs/toolkit";
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from "@reduxjs/toolkit/query";
import { graphApi } from "./GraphAPI";
import View from "./ViewTypes";
import viewReducer from "./ViewSlice"

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [graphApi.reducerPath]: graphApi.reducer,
    authReducer: (
      state: object = { accesstoken: "" },
      action: { type: string; payload: string } | UnknownAction
    ) => {
      // Check to see if the reducer cares about this action
      if (action.type === "auth/token") {
        // If so, make a copy of `state`
        return {
          ...state,
          // and update the copy with the new value
          accesstoken: action.payload,
        };
      }
      // otherwise return the existing state unchanged
      return state;
    },
    quackReducer: (
      state: { quack: unknown } = { quack: false},
      action: { type: string; payload: boolean } | UnknownAction
    ) => {
      if (action.type === "quack/quack") {
        return {
          ...state,
          quack: action.payload,
        };
      }
      return state;
    },
    viewReducer: viewReducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(graphApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
