import { Box, Center, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import DocumentCard from "../components/DocumentCard";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import SearchBar from "../components/SearchBar";
import { useAppSelector } from "../lib/hooks";

export default function MainTabLayout() {
  const start_docs = [
    {
      title: "Torture",
      cases: ["hudoc:001-58287", "hudoc:001-87305", "hudoc:001-57506"],
    },
    { title: "Eur-Lex Demo", cases: ["CELEX:62019CJ0481"] },
    { title: "Proportionality", cases: ["hudoc:001-90235", "hudoc:001-58369"] },
    {
      title: "Right to private life",
      cases: ["hudoc:001-105415", "hudoc:001-147117"],
    },
    { title: "Right to housing", cases: ["hudoc:001-61188"] },
    {
      title: "Right to property",
      cases: [
        "hudoc:001-73198",
        "hudoc:001-123422",
        "hudoc:001-57580",
        "hudoc:001-111420",
      ],
    },
    { title: "Religious freedom", cases: ["hudoc:001-145466"] },
    { title: "Discrimination", cases: ["hudoc:001-161149"] },
    { title: "Health", cases: ["hudoc:001-57905"] },
    { title: "Democracy", cases: ["hudoc:001-70442"] },
    {
      title: "Rights trade-off",
      cases: [
        "hudoc:001-140015",
        "hudoc:001-179882",
        "hudoc:001-115881",
        "hudoc:001-109029",
        "hudoc:001-92137",
      ],
    },
    { title: "Double punishment", cases: ["hudoc:001-168972"] },
    { title: "Right to Offend, Shock or Disturb", cases: ["hudoc:001-57499"] },

  ];
  const search = useAppSelector(state => state.viewReducer.search)
  return (
    <Center>
      <Stack width={["90%", "80%", "80%", "80%", "80%", "80%"]}>
        <SearchBar />
        <Accordion allowMultiple width="100%">
          {search ?
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text as="b">Search</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {isExpanded ? <>{/* Here the folder serves as transporter for the category name. this is not a good solution, might rewrite */}
                      <SimpleGrid columns={[1, 1, 1, 2, 2, 3, 3]} spacing={10} height="100%">
                        {search.map((node) => (
                          <DocumentCard
                            id={node.properties.id}
                            nodeId={node.elementId}
                            key={node.elementId}
                            folder={{ documents: [], id: "", name: "Search" }}
                          />
                        ))}
                      </SimpleGrid></> : null}

                  </AccordionPanel>
                </>
              )}
            </AccordionItem> : null}
          {start_docs.map((value) => (
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Text as="b">{value.title}</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {isExpanded ? <>
                      {/* Here the folder serves as transporter for the category name. this is not a good solution, might rewrite */}
                      <SimpleGrid columns={[1, 1, 1, 2, 2, 3, 3]} spacing={10} height="100%">
                        {value.cases.map((node) => (
                          <DocumentCard
                            id={node}
                            nodeId={node}
                            key={node}
                            folder={{ documents: [], id: "", name: value.title }}
                          />
                        ))}
                      </SimpleGrid>
                    </> : null}
                  </AccordionPanel>
                </>)}
            </AccordionItem>
          ))}
        </Accordion>
      </Stack>
    </Center>
  );
}
