import { useEffect, useState } from "react";

import "@react-sigma/core/lib/react-sigma.min.css";
import {
  useRegisterEvents,
  useSigma,
} from "@react-sigma/core";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { store } from "../../lib/Store";

const GraphEvents = (props: {
  onHoverNode: Function;
  onClickNode: Function;
  // setTimelinePosition: Function;
  graphViewIndex: number;
  isActive?: boolean;
  floatingDocuments: Array<{ id: string, url: string, coords: { x: number, y: number }, graphViewIndex: number, nodeId: string }>
  setFloatingDocuments: Function;
}) => {
  const registerEvents = useRegisterEvents();
  const sigma = useSigma();
  const [hoveredNode, setHoveredNode] = useState<string>();
  const dispatch = useAppDispatch();
  const graph = sigma.getGraph();

  const countryFilter = useAppSelector(
    (state) =>
      state.viewReducer.graphViews[props.graphViewIndex].filter.countrys
  );
  const importanceFilter = useAppSelector(
    (state) =>
      state.viewReducer.graphViews[props.graphViewIndex].filter.importance
  );
  const decisionLevelFilter = useAppSelector(
    (state) =>
      state.viewReducer.graphViews[props.graphViewIndex].filter.decisionLevel
  );
  const timeFilter = useAppSelector(
    (state) =>
      state.viewReducer.graphViews[props.graphViewIndex].filter.timeFilter
  );

  // Filter implementation
  useEffect(() => {
    let filteredNodes: string[] = graph.nodes()
    filteredNodes = filteredNodes.filter((node) => importanceFilter.includes(graph.getNodeAttribute(node, "importance")))
    filteredNodes = filteredNodes.filter((node) => decisionLevelFilter.includes(graph.getNodeAttribute(node, "chambertype")))
    filteredNodes = filteredNodes.filter((node) => {
      const countries = graph.getNodeAttribute(node, "country")
      for (let country of countries) {
        if (countryFilter.includes(country)) return true
      }
      return false
    })
    filteredNodes = filteredNodes.filter((node) => {
      let nodeDate = new Date(
        graph.getNodeAttribute(node, "judgementdate")
      ); return nodeDate.getTime() >= timeFilter.lower &&
        nodeDate.getTime() <= timeFilter.upper
    })

    graph.forEachNode(node => {
      if (filteredNodes.includes(node)) {
        graph.setNodeAttribute(node, "hidden", false)
      } else {
        graph.setNodeAttribute(node, "hidden", true)
      }
    })

  }, [importanceFilter, decisionLevelFilter, timeFilter.lower, timeFilter.upper, countryFilter, graph, sigma]);

  useEffect(() => {
    if (props.isActive) {
      sigma.refresh();
    }
  }, [props.isActive, sigma]);

  useEffect(() => {
    // Register the events
    registerEvents({
      updated: (event) => {
        // props.setTimelinePosition(sigma.graphToViewport({x:0, y:0}))
        if (
          store.getState().viewReducer.graphViews[props.graphViewIndex]
            .nodeContextMenu
        )
          dispatch({
            type: "view/removeNodeContextMenu",
            payload: { graphViewIndex: props.graphViewIndex },
          });
        let floats = [...props.floatingDocuments]

        for (let float of floats) {
          let attrs = graph.getNodeAttributes(float.nodeId)
          float.coords = sigma.graphToViewport({
            x: attrs["x"],
            y: attrs["y"]
          })
        }
        props.setFloatingDocuments(floats)
      },
      clickNode: (event) => {
        if (!props.floatingDocuments.find(node => node.nodeId === event.node)) {
          let node_attributes = graph.getNodeAttributes(event.node);
          let floats = [...props.floatingDocuments]
          floats.push({
            graphViewIndex: props.graphViewIndex,
            nodeId: event.node,
            url: node_attributes.url,
            id: node_attributes.id,
            coords: sigma.graphToViewport({
              x: node_attributes.x,
              y: node_attributes.y,
            }),
          })
          props.setFloatingDocuments(floats)
        }

        props.onHoverNode(undefined);
      },
      enterNode: (event) => {
        if (
          graph.getNodeAttribute(event.node, "nodeType") ===
          "Document" && !props.floatingDocuments.find(node => node.nodeId === event.node)
        ) {
          props.onHoverNode({
            node: graph.getNodeAttributes(event.node).id,
            event: event.event,
          });
          let neighbours = graph.neighbors(event.node);

          graph.forEachNode((node) => {
            if (!neighbours.includes(node)) {
              graph.setNodeAttribute(node, "color", "#E2E2E2");
            } else {
              graph.setNodeAttribute(
                node,
                "color",
                graph.getNodeAttribute(node, "originalColor")
              );
            }
          });
          graph.setNodeAttribute(
            event.node,
            "color",
            graph.getNodeAttribute(event.node, "originalColor")
          );
          graph.forEachEdge((edge) => {
            if (!graph.extremities(edge).includes(event.node)) {
              graph.setEdgeAttribute(edge, "color", "#00000000");
            } else {
              graph.setEdgeAttribute(edge, "color", "#cccccc");
            }
          });
        }
      },
      leaveNode: (payload) => {
        props.onHoverNode(undefined);
        graph.forEachNode((node) =>
          graph.setNodeAttribute(
            node,
            "color",
            graph.getNodeAttribute(node, "originalColor")
          )
        );
        graph.forEachEdge((edge) =>
          graph.setEdgeAttribute(edge, "color", "#cccccc")
        );
      },
      downNode: (e) => {
        props.onHoverNode(undefined);
      },
      mouseup: (e) => { },
      mousedown: (e) => {
        // Disable the autoscale at the first down interaction
        if (!sigma.getCustomBBox()) sigma.setCustomBBox(sigma.getBBox());
      },
      mousemove: (e) => { },
      rightClickNode: (event) => {
        event.event.original.preventDefault();
        dispatch({
          type: "view/setNodeContextMenu",
          payload: {
            graphViewIndex: props.graphViewIndex,
            nodeContextMenu: {
              id: graph.getNodeAttribute(event.node, "id"),
              nodeId: event.node,
              coords: { x: event.event.x, y: event.event.y },
            },
          },
        });
      },
    });
  }, [registerEvents, sigma, hoveredNode, dispatch, graph, props]);

  return null;
};

export default GraphEvents;
