import { Button, Card, CardBody, CardFooter, CloseButton, HStack, Heading, Menu, MenuButton, MenuItem, MenuList, Spinner, Stack, Tag, Text, Tooltip, theme } from "@chakra-ui/react";
import { useGetFileQuery, useGetNodeQuery } from "../../lib/GraphAPI";
import { Folder } from "../../lib/ViewTypes";
import { useAppDispatch } from "../../lib/hooks";
import { motion } from "framer-motion";
import { ImportanceNames } from "../../lib/APITypes";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { importance_level_tooltips } from "../../strings/tooltips";


export default function SavedDocumentCard(props: {
    id: string;
    nodeId?: string;
    folder?: Folder;
}) {
    // const { data: fileData, error: fileError, isLoading: fileIsLoading, isSuccess: fileIsSuccess } = useGetFileQuery(props.id);
    const { data: nodeData, error: nodeError, isLoading: nodeIsLoading, isSuccess: nodeIsSuccess } = useGetNodeQuery(props.id);

    const dispatch = useAppDispatch();

    function openModal() {
        dispatch({ type: "view/openDocument", payload: props.id });
    }

    function removeDoc() {
        dispatch({
            type: "view/removeDoc",
            payload: { folderId: props.folder?.id, nodeId: props.id },
        });
    }

    return (
        <motion.div
            initial={{ opacity: 0, height: "0", x: -100 }}
            animate={{ opacity: 1, height: "auto", x: 0 }}
        >
            <Card width="100%" zIndex={10} style={{ boxShadow: "0px 4px 20px -4px #00000080" }}>
                <CardBody>
                    <Stack spacing="3">
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            {nodeIsLoading ? (
                                <Spinner />
                            ) : (
                                <Heading size="md" whiteSpace={"normal"}>
                                    {nodeData ? nodeData.properties.label : "Something went wrong"}
                                </Heading>
                            )} <CloseButton onClick={() => removeDoc()} />
                        </Stack>
                        <HStack spacing={1} flexWrap={"wrap"}>
                            {nodeData?.properties.importance ? (<Tooltip label={importance_level_tooltips[nodeData.properties.importance]}>
                                <Tag variant={"solid"} bgColor={theme.colors.blue[900]}>{ImportanceNames[nodeData?.properties.importance]}</Tag>
                            </Tooltip>) : null}
                            {nodeData?.properties.violation ? nodeData?.properties.violation.split(",").map(violation => <Tag bgColor={theme.colors.red[600]} textColor={"white"}>Article {violation}</Tag>) : null}
                            {nodeData?.properties.nonviolation ? nodeData?.properties.nonviolation.split(",").map(nonviolation => <Tag bgColor={theme.colors.green[600]} textColor={"white"}>Article {nonviolation}</Tag>) : null}
                            {nodeData?.properties.keywords ? nodeData?.properties.keywords.map(keyword => <>
                                <Tooltip label={keyword}>
                                    <Tag bgColor={theme.colors.yellow[500]} textColor={"white"}>{keyword.length > 10 ? keyword.substring(0, 10) + "..." : keyword} </Tag>
                                </Tooltip>
                            </>) : null}
                        </HStack>
                        {nodeData ? <HStack spacing={2}>
                            <Text fontSize="14px" fontWeight={600} color={theme.colors.gray[700]}>Date: {new Date(nodeData.properties.judgementdate!).toLocaleDateString('de-DE')} </Text>
                            <Text fontSize="14px" fontWeight={600} color={theme.colors.gray[700]}>|</Text>
                            <Text fontSize="14px" fontWeight={600} color={theme.colors.gray[700]}>Appno: {<>
                                <Tooltip label={nodeData.properties.appno.replaceAll(";", "  ")}>
                                    {nodeData.properties.appno.length > 10 ? nodeData.properties.appno.substring(0, 10) + "..." : nodeData.properties.appno}
                                </Tooltip>
                            </>}</Text>
                        </HStack> : <Spinner />}
                        {nodeIsLoading ? (
                            <Spinner colorScheme="purple" />
                        ) : (
                            <Text fontSize="14px" whiteSpace={"normal"}>
                                <Text fontWeight={600} color={theme.colors.gray[700]}>Conclusion</Text>
                                {nodeData?.properties.conclusion.slice(0, 200)}...</Text>
                        )}
                    </Stack>
                </CardBody>
                <CardFooter>
                    <HStack width="100%" justifyContent={"space-between"}>
                        <Menu >
                            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                Open
                            </MenuButton>
                            <MenuList maxH="200px">
                                <MenuItem onClick={event => openModal()}>Open Modal</MenuItem>
                                <MenuItem onClick={event => window.open(nodeData?.properties.url, '_blank')?.focus()}>Open in HUDOC</MenuItem>
                            </MenuList>
                        </Menu>

                        <Button
                            variant="solid"
                            colorScheme="purple"
                            onClick={(event) => removeDoc()}
                        >
                            Remove Document
                        </Button>
                    </HStack>

                </CardFooter>
            </Card>
        </motion.div>
    );
}
