import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CloseButton,
  HStack,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Tag,
  Text,
  Tooltip,
  theme,
  useToast,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../lib/hooks";
import { useGetFileQuery, useGetNodeQuery } from "../lib/GraphAPI";
import { motion } from "framer-motion";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { ImportanceNames } from "../lib/APITypes";
import { importance_level_tooltips } from "../strings/tooltips";

export default function FloatingDocument(props: {
  id: string;
  url: string;
  position: { x: number; y: number };
  onClose: (id: string) => void;
}) {
  // const {
  //   data: fileData,
  //   error: fileError,
  //   isLoading: fileIsLoading,
  //   isSuccess: fileIsSuccess,
  // } = useGetFileQuery(props.id);
  const {
    data: nodeData,
    error: nodeError,
    isLoading: nodeIsLoading,
    isSuccess: nodeIsSuccess,
  } = useGetNodeQuery(props.id);

  const dispatch = useAppDispatch();
  const toast = useToast();
  const folders = useAppSelector((state) => state.viewReducer.folders);

  function openModal() {
    dispatch({ type: "view/openDocument", payload: props.id });
  }

  return (
    <motion.div
      initial={{ opacity: 0, height: "0" }}
      animate={{ opacity: 1, height: "auto" }}
      transition={{ duration: 0.5 }}
    >
      <div
        style={{
          width: 400,
          height: "fit-content",
          position: "absolute",
          zIndex: "10",
          top: props.position.y + 20,
          left: props.position.x - 200,
        }}
      >
        <Card
          width="100%"
          zIndex={10}
          style={{ boxShadow: "0px 4px 20px -4px #00000080" }}
        >
          <CardBody>
            <Stack spacing="3">
              <Stack direction={"row"} justifyContent={"space-between"}>
                {nodeIsLoading ? (
                  <Spinner />
                ) : (
                  <Heading size="md">
                    {nodeData ? nodeData.properties.label : "Something went wrong"}
                  </Heading>
                )}{" "}
                <CloseButton onClick={() => props.onClose(props.id)} />
              </Stack>
              <HStack spacing={1} flexWrap={"wrap"}>
                {nodeData?.properties.importance ? (
                  <Tooltip label={importance_level_tooltips[nodeData.properties.importance]}>
                    <Tag variant={"solid"} bgColor={theme.colors.blue[900]}>
                      {ImportanceNames[nodeData?.properties.importance]}
                    </Tag>
                  </Tooltip>
                ) : null}
                {nodeData?.properties.violation
                  ? nodeData?.properties.violation
                    .split(",")
                    .map((violation) => (
                      <Tag
                        bgColor={theme.colors.red[600]}
                        textColor={"white"}
                      >
                        Article {violation}
                      </Tag>
                    ))
                  : null}
                {nodeData?.properties.nonviolation
                  ? nodeData?.properties.nonviolation
                    .split(",")
                    .map((nonviolation) => (
                      <Tag
                        bgColor={theme.colors.green[600]}
                        textColor={"white"}
                      >
                        Article {nonviolation}
                      </Tag>
                    ))
                  : null}
                {nodeData?.properties.keywords
                  ? nodeData?.properties.keywords.map((keyword) => (
                    <>
                      <Tooltip label={keyword}>
                        <Tag
                          bgColor={theme.colors.yellow[500]}
                          textColor={"white"}
                        >
                          {keyword.length > 10
                            ? keyword.substring(0, 10) + "..."
                            : keyword}{" "}
                        </Tag>
                      </Tooltip>
                    </>
                  ))
                  : null}
              </HStack>
              {nodeData ? (
                <HStack spacing={2}>
                  <Text
                    fontSize="14px"
                    fontWeight={600}
                    color={theme.colors.gray[700]}
                  >
                    Date:{" "}
                    {new Date(
                      nodeData.properties.judgementdate!
                    ).toLocaleDateString("de-DE")}{" "}
                  </Text>
                  <Text
                    fontSize="14px"
                    fontWeight={600}
                    color={theme.colors.gray[700]}
                  >
                    |
                  </Text>
                  <Text
                    fontSize="14px"
                    fontWeight={600}
                    color={theme.colors.gray[700]}
                  >
                    Appno:{" "}
                    {
                      <>
                        <Tooltip
                          label={nodeData.properties.appno.replaceAll(
                            ";",
                            "  "
                          )}
                        >
                          {nodeData.properties.appno.length > 10
                            ? nodeData.properties.appno.substring(0, 10) + "..."
                            : nodeData.properties.appno}
                        </Tooltip>
                      </>
                    }
                  </Text>
                </HStack>
              ) : (
                <Spinner />
              )}
              {nodeIsLoading ? (
                <Spinner colorScheme="purple" />
              ) : (
                <Text fontSize="14px">
                  <Text fontWeight={600} color={theme.colors.gray[700]}>
                    Conclusion
                  </Text>
                  {nodeData?.properties.conclusion.slice(0, 200)}...
                </Text>
              )}
            </Stack>
          </CardBody>
          <CardFooter>
            <HStack width="100%" justifyContent={"space-between"}>
              <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                  Open
                </MenuButton>
                <MenuList maxH="200px">
                  <MenuItem onClick={(event) => openModal()}>
                    Open Modal
                  </MenuItem>
                  <MenuItem
                    onClick={(event) =>
                      window.open(props.url, "_blank")?.focus()
                    }
                  >
                    Open in HUDOC
                  </MenuItem>
                </MenuList>
              </Menu>
              <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                  Save in folder
                </MenuButton>
                <MenuList maxH="200px">
                  {folders.map((folder) => (
                    <MenuItem
                      onClick={(event) => {
                        dispatch({
                          type: "view/saveDoc",
                          payload: { folderId: folder.id, nodeId: props.id },
                        });
                        toast({
                          title: "Case saved",
                          description:
                            `Your case has been saved in "${folder.name}"`,
                          status: "info",
                          duration: 5000,
                          isClosable: true,
                        });
                      }}
                    >
                      {folder.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </HStack>
          </CardFooter>
        </Card>
      </div>
    </motion.div>
  );
}
