import { useAuth } from "oidc-react";
import { Link } from "react-router-dom";
import { store } from "../lib/Store";
import TabLayout from "../layouts/TabLayout"
export default function Index() {
  var auth = useAuth();
  if (auth.userData) {
    store.dispatch({ type: "auth/token", payload: auth.userData.access_token });
    return (
      <TabLayout/>
    );
  } else {
    return (
      <>
        You are not logged in and cant access this content
        <button onClick={() => auth.signIn()}>Login</button>
      </>
    );
  }
}
